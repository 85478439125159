<template>
  <div class="onboarding-page__wrapper">
    <div class="intro-page">
      <h2>
        크리에이터님 환영합니다 :)<br />
        빅크리에이터가 되는 여정을<br class="mo" />
        함께 해볼까요?
      </h2>

      <p class="sub-text-s2 text-gray-second">
        빅크 스튜디오를 이용하기 위해서는 빅크리에이터 신청 마지막 단계까지
        완료해야 해요.
      </p>

      <card-basic class="intro-card">
        <ul>
          <li>
            <span class="check-icon__wrapper active">
              <check-icon
                fill-color="#ffffff"
                width="8"
                height="8"
              ></check-icon>
            </span>
            <div class="text">
              <span class="sub-text-s2 title">빅크리에이터 신청</span>
              <p class="b-text-3 text-gray-second mo">
                신청에 필요한 항목들을 작성해서 신청해주세요.
              </p>
              <p class="b-text-3 text-gray-second pc">
                신청에 필요한 항목들을 작성해서 신청해주세요. 5분이면 신청 완료!
              </p>
            </div>
          </li>
          <li>
            <span class="check-icon__wrapper">
              <check-icon
                fill-color="#ffffff"
                width="8"
                height="8"
              ></check-icon>
            </span>
            <div class="text">
              <span class="sub-text-s2 title">신청 사항 확인</span>
              <p class="b-text-3 text-gray-second mo">
                심사 정책을 바탕으로 작성해주신 항목을 확인해요.
              </p>
              <p class="b-text-3 text-gray-second pc">
                빅크 심사 정책을 바탕으로 작성해주신 항목을 확인해요.
              </p>
            </div>
          </li>
          <li>
            <span class="check-icon__wrapper">
              <check-icon
                fill-color="#ffffff"
                width="8"
                height="8"
              ></check-icon>
            </span>
            <div class="text">
              <span class="sub-text-s2 title">확인 완료</span>
              <p class="b-text-3 text-gray-second mo">
                확인 완료 후 승인이 되면 Starter로 기본 설정돼요.
              </p>
              <p class="b-text-3 text-gray-second pc">
                확인 완료 후 승인이 되면 Starter로 기본 설정돼요.
              </p>
            </div>
          </li>
          <li>
            <span class="check-icon__wrapper">
              <check-icon
                fill-color="#ffffff"
                width="8"
                height="8"
              ></check-icon>
            </span>
            <div class="text">
              <span class="sub-text-s2 title">빅크리에이터 신청</span>
              <p class="b-text-3 text-gray-second mo">
                빅크 스튜디오를 맘껏 사용해보세요!
              </p>
              <p class="b-text-3 text-gray-second pc">
                32가지의 강력한 기능을 가진 빅크 스튜디오를 맘껏 사용해보세요!
              </p>
            </div>
          </li>
        </ul>
      </card-basic>

      <button-basic
        class="start-btn"
        text="좋아요!"
        @action="actions.goToProfile()"
      ></button-basic>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import onBoardingConst from "@/consts/onboarding";
import CardBasic from "../../../../components/console/cards/CardBasic";
import CheckIcon from "../../../../components/console/icons/CheckIcon";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";

export default {
  name: "OnBoardingIntro",
  components: { ButtonBasic, CheckIcon, CardBasic },
  setup() {
    const router = useRouter();
    const store = useStore();

    const actions = {
      goToProfile: () => {
        store
          .dispatch("onBoardings/putOnBoardingStep", {
            step: onBoardingConst.onBoardingSteps.start,
          })
          .then(() => {
            router.push({ name: "console.onboarding.profile" });
          });
      },
    };

    return { actions };
  },
};
</script>

<style src="./style.css" scoped></style>
